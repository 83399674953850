import merge from 'lodash/merge';
import set from 'lodash/set';

const textLight = {
  on: '#fff',
  onVariant: '#9a9a9a',
};

const textDark = {
  on: '#000',
  onVariant: '#9a9a9a',
};

const yellow = {
  main: '#ffff64',
  variant: '#ffff99',
  ...textDark,
  onVariant: 'rgba(0,0,0,0.15)',
  labelOnColor: '#666',
};

const purple = {
  main: '#99a7ee',
  variant: '#7d8fe8',
  ...textDark,
};

const red = {
  main: '#ff1464',
  ...textLight,
};

const verified = {
  main: '#38ef51',
  variant: '#99f2a5',
  ...textDark,
};

const successGreen = {
  main: '#24c609',
  ...textDark,
};

const errorRed = {
  main: '#e51212',
  ...textLight,
};

const cosign = {
  ...yellow,
};

export const light = {
  main: '#fff',
  variant: '#e9e9e9',
  blockOnColor: '#ccc',
  blockOnVariant: '#dddddd',
  iqPoints: successGreen.main,
  neutralPoints: '#666',
  positiveNumber: successGreen.main,
  negativeNumber: errorRed.main,
  ...textDark,
};

const dark = {
  main: '#121212',
  variant: '#2a2a2a',
  ...textLight,
};

const darkGray = {
  main: '#1c1c1c',
  variant: '#333333',
  ...textLight,
};

const headerGray = {
  main: '#272727',
  variant: '#383838',
  ...textLight,
};

const diff = {
  insert: '#6ee07d',
  delete: '#fa7878',
};

const mediator = {
  main: '#ff518c',
  ...textDark,
};

const transcriber = {
  main: '#ffa335',
  ...textDark,
};

const moderator = {
  main: '#7689e8',
  ...textDark,
};

const staff = {
  main: '#b0c4de',
  ...textDark,
};

const backgroundLight = {
  main: '#fff',
};

const redText = {
  on: '#ff1464',
  ...backgroundLight,
};

export const lightTheme = {
  name: 'light',
  color: {
    background: light,
    primary: yellow,
    secondary: purple,
    accent: red,
    accentText: redText,
    brandPrimary: yellow,
    brandSecondary: purple,
    brandAccent: red,
    selection: '#b2d7fe',
    verified,
    verifiedArtist: yellow,
    success: successGreen,
    error: errorRed,
    contributor: light,
    transcriber,
    mediator,
    editor: yellow,
    moderator,
    staff,
    diff,
    cosign,
    banners: {
      unreviewed: '#ffecf3',
      inProgress: '#f0f0f0',
      background: '#fff',
    },
  },
  font: {
    reading: '\'Programme\', Arial, sans-serif',
    accent: '\'Programme\', Arial, sans-serif',
    alternate: '\'HelveticaNeue\', Arial, sans-serif',
    monospace: '\'Consolas\', \'Monaco\', \'Lucida Console\', \'Liberation Mono\', \'DejaVu Sans Mono\', \'Bitstream Vera Sans Mono\', \'Courier New\', monospace',
    programmeAlternates: '\'ss07\', \'ss08\', \'ss11\', \'ss12\', \'ss14\', \'ss15\', \'ss16\', \'ss18\', \'ss19\', \'ss20\', \'ss21\'',
  },
  borderRadius: '1.25rem',
  space: {
    hair: '.125rem',
    quarter: '.25rem',
    xSmall: '.375rem',
    half: '.5rem',
    small: '.75rem',
    full: '1rem',
    large: '1.313rem',
    xLarge: '1.5rem',
    xxLarge: '2.25rem',
    xxxLarge: '3rem',
    xxxxLarge: '3.75rem',
  },
  fontSize: {
    xxSmallReading: '.5rem',
    xSmallReading: '.625rem',
    smallReading: '.75rem',
    reading: '1rem',
    largeReading: '1.125rem',
    xSmallHeadline: '1.25rem',
    smallHeadline: '1.5rem',
    medium: '2rem',
    headline: '2.25rem',
    largeHeadline: '3rem',
    xLargeHeadline: '3.75rem',
    xxLargeHeadline: '5rem',
  },
  fontWeight: {
    light: '100',
    normal: '400',
    bold: '700',
  },
  lineHeight: {
    programmeCapHeight: '0.7',
    xShort: '1.1',
    short: '1.125',
    normal: '1.2',
    medium: '1.33',
    reading: '1.45',
    largeReading: '1.65',
  },
  stickyNav: {
    height: {
      desktop: '3rem',
      mobile: '36px',
    },
  },
  border: {
    standardWidth: '.15rem',
    standardWidthPixels: '2px',
  },
  listIndentationWidth: '1.6em',
  header: {
    height: '41px',
    heightWithSubnavigation: '82px',
  },
  boxShadow: {
    standard: '0 4px 8px rgba(0, 0, 0, 0.2)',
    tooltip: '0 7px 25px 0 rgba(0, 0, 0, 0.10)',
  },
  grid: {
    gap: '0.75rem',
    rowGap: '1rem',
    columnWidth: '5rem',
    largeColumnWidth: '6rem',
    outerMargin: '2.25rem',
  },
  widths: {
    condensedGridBreakpoint: '1188px',
    fixedWidthBreakpoint: '1164px',
    largeTextBreakpoint: '1526px',
  },
};

export const darkTheme = merge({}, lightTheme, {
  name: 'dark',
  color: {
    background: dark,
    accent: yellow,
  },
});

export const stickyNavTheme = merge({}, lightTheme, {
  color: {
    primary: light,
    background: dark,
  },
  baseColors: {
    gradient: dark,
  },
});

const headerByTheme = {
  light: {
    color: {
      background: yellow,
      primary: light,
    },
  },
  dark: {
    color: {
      background: headerGray,
      primary: dark,
    },
  },
};

export const headerTheme = theme => merge({}, theme, headerByTheme[theme.name]);

const surfaceByTheme = {
  light: {
    color: {
      background: dark,
      accent: yellow,
    },
  },
  dark: {
    color: {
      background: darkGray,
      accent: red,
    },
  },
  songPageLight: {
    color: {
      background: dark,
      accent: yellow,
    },
  },
};

export const surfaceTheme = theme => merge({}, theme, surfaceByTheme[theme.name]);

export const themeOverride = (theme, key, value) => merge({}, theme, set({}, key, value));
