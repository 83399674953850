import React, {useCallback, useRef} from 'react';
import PropTypes from 'prop-types';
import {Controller} from 'react-hook-form';

import {
  FieldContainer,
  FieldLabel,
  FieldError,
  FieldHelpText,
} from './Field.shared';

const SelectField = ({
  children,
  className,
  disabled,
  helpText,
  hideLabel,
  isRequired,
  label,
  showErrors,
  transformForForm,
  transformForInput,
  ...controllerProps
}) => {
  const fieldOnChange = useCallback(field => async (value) => {
    if (transformForForm) {
      const newValue = transformForForm(value);
      field.onChange(newValue);
    } else {
      field.onChange(value);
    }
  }, [transformForForm]);

  const firstChildrenRender = useRef(true);
  const inputDefaultValue = useRef();
  const renderChildren = useCallback(({field, fieldState}) => {
    const fieldError = fieldState.error;
    if (firstChildrenRender.current) {
      inputDefaultValue.current = transformForInput(field.value);
      firstChildrenRender.current = false;
    }
    return (
      <FieldContainer className={className}>
        {!hideLabel && <FieldLabel>{label}&nbsp;{isRequired && '*'}</FieldLabel>}
        {children({
          dataTestId: `${field.name}-input`,
          defaultValue: inputDefaultValue.current,
          disabled,
          hasError: fieldState.invalid,
          onChange: fieldOnChange(field),
        })}
        {fieldError && showErrors && (
          <FieldError>{fieldError.message}</FieldError>
        )}
        {helpText && (
          <FieldHelpText>{helpText}</FieldHelpText>
        )}
      </FieldContainer>
    );
  }, [className, children, disabled, fieldOnChange, helpText, hideLabel, isRequired, label, showErrors, transformForInput]);

  return (
    <Controller
      {...controllerProps}
      render={renderChildren}
    />
  );
};

export default React.memo(SelectField);

SelectField.propTypes = {
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  helpText: PropTypes.string,
  hideLabel: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  showErrors: PropTypes.bool,
  transformForForm: PropTypes.func,
  transformForInput: PropTypes.func,
  triggerOnChange: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

SelectField.defaultProps = {
  disabled: false,
  hideLabel: false,
  isRequired: false,
  showErrors: true,
  transformForInput: value => value,
};
