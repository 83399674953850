import React, {useEffect, useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import range from 'lodash/range';
import once from 'lodash/once';
import {useTranslation} from 'react-i18next';

import {useIsMobile, useGlobalEvent, useMixpanelEvent} from '@/hooks';
import {SelectInput} from '@/songPage/components/controls';
import CalendarToggle from './CalendarToggle';

const monthsWith31Days = [1, 3, 5, 7, 8, 10, 12];
const monthsWith30Days = [4, 6, 9, 11];

const calculateDaysInMonth = (selectedMonth, year) => {
  let numDays;

  if (monthsWith31Days.includes(selectedMonth)) {
    numDays = 31;
  } else if (monthsWith30Days.includes(selectedMonth)) {
    numDays = 30;
  } else {
    const isLeap = new Date(year, 1, 29).getMonth() === 1;
    numDays = isLeap ? 29 : 28;
  }
  return numDays;
};

const getDatePicker = once(
  async () => {
    const [, datepicker] = await Promise.all([
      import(
        /* webpackChunkName: "reactDatepickerCSS" */
        'react-datepicker/dist/react-datepicker.css'
      ),
      import(
        /* webpackChunkName: "reactDatepicker" */
        'react-datepicker'
      ),
    ]);
    return datepicker;
  }
);

const DateInput = ({
  onChange,
  disabled,
  initialMonth,
  initialDay,
  initialYear,
  onCalendarToggleClick,
  clearable,
  minimumYear,
  noCalendar,
  className,
  customStyles,
}) => {
  const currentYear = new Date().getFullYear();
  const maxYear = currentYear + 1;
  const {t} = useTranslation();
  const isMobile = useIsMobile();

  const [ReactDatePicker, setReactDatePicker] = useState(null);
  const showDatePicker = !noCalendar;

  const getMonthName = useCallback(index => t(`date_input.months.${index}`), [t]);
  const [calendarPreviewDate, setCalendarPreviewDate] = useState(new Date());
  const [month, setMonth] = useState(initialMonth);
  const [day, setDay] = useState(initialDay);
  const [year, setYear] = useState(initialYear);
  const [daysToAdd, setDaysToAdd] = useState(calculateDaysInMonth(new Date().getMonth() + 1, new Date().getFullYear()));
  const [calendarOpen, setCalendarOpen] = useState(false);
  const callback = useCallback(dateParts => onChange(dateParts), [onChange]);

  const trackCalendarDateSet = useMixpanelEvent('song:metadata:calendar_select_date');

  useEffect(() => {
    if (showDatePicker && !ReactDatePicker) {
      getDatePicker().then((c) => {
        setReactDatePicker(() => c.default);
      });
    }
  }, [showDatePicker, ReactDatePicker]);

  useEffect(() => {
    const daysInMonth = calculateDaysInMonth(Number(month), year);
    setDaysToAdd(daysInMonth);
  }, [month, year]);

  useEffect(() => {
    if (!day && !month && !year) {
      setCalendarPreviewDate(new Date());
    }
  }, [day, month, year]);

  const monthOptions = range(1, 13).map(m => ({label: getMonthName(m), value: m}));

  const dayOptions = range(1, daysToAdd + 1).map(i => ({label: i, value: i}));

  const yearOptions = range(maxYear, minimumYear, -1).map(i => ({label: i, value: i}));

  const handleCalendarSelection = useCallback((d) => {
    setCalendarPreviewDate(d);
    setYear(d.getFullYear());
    setMonth(d.getMonth() + 1);
    setDay(d.getDate());
    setCalendarOpen(false);
    trackCalendarDateSet({
      'Current': (new Date()).toDateString(),
      'Target': d.toDateString(),
      'Is Today?': (new Date()).toDateString() === d.toDateString(),
    });
  }, [trackCalendarDateSet]);

  const handleSetDay = useCallback((newDay) => {
    if (newDay === null) {
      setDay(null);
    } else {
      setDay(newDay.value);
    }
  }, []);

  const handleSetMonth = useCallback((newMonth) => {
    if (newMonth === null) {
      setMonth(null);
    } else {
      setMonth(newMonth.value);
    }
  }, []);

  const handleSetYear = useCallback((newYear) => {
    if (newYear === null) {
      setYear(null);
    } else {
      setYear(newYear.value);
    }
  }, []);

  useEffect(() => {
    callback({month, day, year});
    setCalendarPreviewDate(new Date(year ?? currentYear, (month ?? 1) - 1, day ?? 1));
  }, [callback, day, month, year, currentYear]);

  const handleCalendarToggleClick = useCallback(() => {
    const newValue = !calendarOpen;
    setCalendarOpen(newValue);
    if (onCalendarToggleClick) {
      onCalendarToggleClick(newValue);
    }
  }, [onCalendarToggleClick, calendarOpen]);

  const metadataFormClearEvent = useGlobalEvent('clearSidebarContributorMetadataForm');

  useEffect(() => {
    const clear = () => {
      setDay(null);
      setMonth(null);
      setYear(null);
    };
    metadataFormClearEvent.subscribe(clear);
    return () => {
      metadataFormClearEvent.unsubscribe(clear);
    };
  }, [metadataFormClearEvent]);

  return (
    <DateInput.Root isMobile={isMobile} className={className}>
      <DateInput.SelectInput
        isClearable={clearable}
        placeholder={t('date_input.month')}
        aria-label="Month Dropdown"
        isMobile={isMobile}
        options={monthOptions}
        value={month === null ? null : monthOptions[month - 1]}
        onChange={handleSetMonth}
        disabled={disabled}
        customStyles={customStyles}
      />
      <DateInput.SelectInput
        isClearable={clearable}
        placeholder={t('date_input.day')}
        aria-label="Day Dropdown"
        isMobile={isMobile}
        options={dayOptions}
        value={day ? dayOptions[day - 1] : null}
        onChange={handleSetDay}
        disabled={disabled}
        customStyles={customStyles}
      />
      <DateInput.SelectInput
        isClearable={clearable}
        placeholder={t('date_input.year')}
        aria-label="Year Dropdown"
        isMobile={isMobile}
        options={yearOptions}
        value={year ? yearOptions[maxYear - year] : null}
        onChange={handleSetYear}
        disabled={disabled}
        customStyles={customStyles}
      />
      {
        noCalendar ? null :
          !ReactDatePicker ? <div>{t('date_input.loading')}</div> :
            disabled ? null :
              (
                <ReactDatePicker
                  onClickOutside={() => setCalendarOpen(false)}
                  open={calendarOpen}
                  selected={calendarPreviewDate}
                  onChange={date => handleCalendarSelection(date)}
                  popperPlacement="bottom-end"
                  popperModifiers={[
                    {
                      name: 'offset',
                      options: {
                        offset: [5, 10],
                      },
                    },
                  ]}
                  customInput={
                    <CalendarToggle
                      onToggleClick={handleCalendarToggleClick}
                      isOpen={calendarOpen}
                    />
                  }
                />
              )
      }
    </DateInput.Root>
  );
};

DateInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  initialMonth: PropTypes.number,
  initialDay: PropTypes.number,
  initialYear: PropTypes.number,
  onCalendarToggleClick: PropTypes.func,
  clearable: PropTypes.bool,
  minimumYear: PropTypes.number,
  noCalendar: PropTypes.bool,
  className: PropTypes.string,
  customStyles: PropTypes.object,
};

DateInput.defaultProps = {
  disabled: false,
  initialMonth: null,
  initialDay: null,
  initialYear: null,
  clearable: true,
  minimumYear: 0,
  noCalendar: false,
};

DateInput.displayName = 'DateInput';

export default React.memo(DateInput);

DateInput.Root = styled.div`
  ${p => !p.isMobile && `
    display: flex;
    flex-direction: row;
    align-items: center;
  `}

  .react-datepicker-wrapper {
    ${p => p.isMobile ? 'text-align: center;' : 'flex: 0;'}
  }
`;

DateInput.SelectInput = styled(props => <SelectInput {...props} />)`
  flex: 1;

  ${p => p.isMobile ? 'width: 100%;' : ''}

  &:not(:last-child) {
    ${p => p.isMobile ?
    `margin-bottom: ${p.theme.space.half};` :
    `margin-right: ${p.theme.space.full};`}
  }

  ${p => p.disabled && `&:last-of-type {
    margin-right: 0;
  }`}
`;
